// import Helpers from "./Helpers";
import {scrollTop, generateKey, getWindowSize, splitCharacters} from "../helpers";
import inViewTransitions from '../modules/inViewTransitions';
import inViewTyper from '../modules/inViewTyper';

export default class site {

	constructor() {
		// const helpers = new Helpers();
		
		// helpers.onReady(this.setHomeTextHeight);

		new inViewTransitions();
		new inViewTyper();

		this.setupMenuButton();

		this.setupLinkTransition();
		this.resetPageTransition();

		if(!document.body.classList.contains('template-home')){
			this.setupHeaderTransition();
		}
		

		this.mailingListLabel();

	}

	setupMenuButton(){
		const menuButton = document.querySelector('.site-nav--button');
		const menu = document.querySelector('.site-nav');

		menuButton.addEventListener('click', () => {
			menuButton.setAttribute('aria-expanded', true);
			document.querySelector('.site-nav li:nth-child(1)').focus();
			document.body.classList.toggle('menu-open');
			console.log('clicked');
		});
	}

	mailingListLabel(){
		if(document.querySelector('input.email')){
			let input = document.querySelectorAll('input.email');

			input.forEach((el) => {
				el.addEventListener('focus', () => {
					let label = el.previousElementSibling;
					label.classList.add('hide-label');
					console.log('focused');
				});

				el.addEventListener('blur', () => {
					let label = el.previousElementSibling;
					if(el.value.trim().length === 0){
						label.classList.remove('hide-label');
					}
					
				});
			});
		}
		
	}

	setupHeaderTransition(){
		let scrollNote = 0;

		window.addEventListener('scroll', () => {
			let top = window.scrollY;
			if(top > scrollNote && top > 100){
				// scrolling down
				document.body.classList.add('hide-nav');
				scrollNote = top;
			} else {
				// scrolling up
				// make sure we've scrolled up at least 100 px
				if(top < (scrollNote - 100)){
					document.body.classList.remove('hide-nav');
					scrollNote = top;
					// check we've scrolled at least one block height, otherwise don't show menu
				} else if(top <= 100){
					document.body.classList.add('show-nav');
				}
			}
		});
	}

	setupLinkTransition(){
		document.querySelectorAll('a').forEach((element) => {
			element.addEventListener('click', (e) => {
				this.checkInternalLink(e)
			});
		});
	}

	resetPageTransition(){
		window.addEventListener('pageshow', (e) => {
			document.body.classList.remove('out');
		});
	}

	checkInternalLink(e){
		let link = e.target.closest('a');
		
		if(link.href.indexOf(window.location.origin) !== -1){
			document.body.classList.add('out');

			setTimeout(() => {
				window.location.href = link.href;
			}, 500);
			e.preventDefault();
		}
		
	}

}