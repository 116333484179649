/*

Add in-view--todo to elements to fade in
Add data-in-view-offset="100" to offset loadinb ny 100px
Add data-in-view-delay="100" to delay loading by 100ms

*/

export default class inViewTransitions {

	constructor() {
		
		this.hiddenElements = document.querySelectorAll('.in-view--todo:not(.in-view--visible)');
		this.hiddenNormalElements = document.querySelectorAll('.in-view--todo:not(.in-view--visible):not(.in-view--placeholder)');
		this.hiddenPlaceholderElements = document.querySelectorAll('.in-view--todo.in-view--placeholder:not(.in-view--visible)');

		this.hideAll();
		this.setLoadListners();

		// Pass this down by using an arrow function
		window.addEventListener('scroll', () => this.setScrollEvent());
		// Run once to do initial check
		this.setScrollEvent();
		
	}

	// Hide all elements
	hideAll(){
		this.hiddenElements.forEach(el => {
			el.classList.add('in-view--hidden');
			el.classList.add('in-view--unloaded');
		});
	}

	// Set the load listners on non-placeholder images only
	setLoadListners(elements = this.hiddenNormalElements){
		elements.forEach(el => {
			if (el.complete) {
				window.addEventListener('load', (e) => {
					el.classList.remove('in-view--unloaded');
					el.classList.add('in-view--loaded');
					this.checkInView(el);
				});
			} else {
				el.classList.remove('in-view--unloaded');
				el.classList.add('in-view--loaded');
				this.checkInView(el);
			}
		});
	}

	// Set the scroll event, send this down to the checkInView function
	setScrollEvent(){
		let self = this;
		this.hiddenElements.forEach(el => {
			this.checkInView(el, self);
		});
	}

	// Load an image and return a promise
	loadImage(src) {
		return new Promise((resolve, reject) => {
			const img = document.createElement("img");
			img.src = src;
			img.onload = () => resolve(img);
			img.onerror = reject;
		});
	}

	checkInView(el, self){
		// If there's an offset, add that to the top of each element
		let offset = el.dataset.inViewOffset ?? 0;
		
		if(el.classList.contains('in-view--mobile') && window.innerWidth < 1000){
			offset = 600;
		}

		let elTop = el.offsetTop + parseInt(offset);
		let elBottom = elTop + el.offsetHeight;
		let windowTop = window.pageYOffset;
		let windowBottom = windowTop + window.innerHeight;

		if (elBottom >= windowTop && elTop < windowBottom) {
			// If there's a manual delay
			let delay = parseInt(el.dataset.inViewDelay) ?? 0;
			setTimeout(function(){
				// If there's a placeholder image
				if(el.classList.contains('in-view--placeholder')){

					let placeholderImageSrc = el.getAttribute('data-src');
					// Load the real image and wait
					self.loadImage(placeholderImageSrc).then((img) => {
						// When promise returns true, switch the src
						el.setAttribute('src', placeholderImageSrc);
						el.classList.remove('in-view--placeholder');
						el.classList.remove('in-view--unloaded');
						el.classList.add('in-view--loaded');
						el.classList.remove('in-view--hidden');
						el.classList.add('in-view--visible');
					});
				} else {
					// If no placeholder image, just show the element
					el.classList.remove('in-view--hidden');
					el.classList.add('in-view--visible');
				}
			}, delay);
			// Update the element list with hidden images
			this.hiddenElements = document.querySelectorAll('.in-view--todo:not(.in-view--visible)');
		}
	}
}