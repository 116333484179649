// import Helpers from "./Helpers";
import {scrollTop, generateKey, getWindowSize, splitCharacters} from "../helpers";

export default class about {

	constructor() {
		let self = this;

		this.setVideos();
		window.addEventListener('resize', this.setVideos);
	}

	setVideos(){
		let iframes;
		if(iframes = document.querySelectorAll('iframe')){
			iframes.forEach((element) => {
				let iframe = element;
				iframe.style.height = (iframe.offsetWidth*0.5625) + 'px';
			});
		}
	}
}