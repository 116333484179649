// import Helpers from "./Helpers";
import {scrollTop, generateKey, getWindowSize, splitCharacters} from "../helpers";

export default class about {

	constructor() {
		let self = this;

		let timezones = document.querySelectorAll('.timezone p');
		this.increaseTime(timezones);
		setInterval(() => this.increaseTime(timezones), 1000);
	}

	increaseTime(timezones){
		timezones.forEach((element) => {
			let time = new Date(element.dataset.time);
			let newTime = time.setSeconds(time.getSeconds() + 1);

			// add leading zeroes
			let h = time.getHours().toString().padStart(2, '0');
			let m = time.getMinutes().toString().padStart(2, '0');
			let s = time.getSeconds().toString().padStart(2, '0');
			
			element.innerHTML = h + ':' + m + ':' + s;
			
			// convert back to php timestamp
			var date = new Date(newTime);
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			var hours = date.getHours();
			var minutes = date.getMinutes();
			var seconds = date.getSeconds();

			let newTimestamp = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
			element.dataset.time = newTimestamp;

		});
	}
}