/*

Add typer--todo to elements to fade in
Add data-typer-offset="100" to offset loadinb ny 100px
Add data-typer-delay="100" to delay loading by 100ms

*/

export default class inViewTransitions {

	constructor() {
		
		// document.querySelectorAll('blockquote').forEach(el => {
		// 	el.classList.add('typer--todo');
		// 	el.setAttribute('data-typer-offset', 150);
		// });

		this.hiddenElements = document.querySelectorAll('.typer--todo:not(.typer-complete)');

		this.hideAll();

		// Pass this down by using an arrow function
		window.addEventListener('scroll', () => this.setScrollEvent());
		// Run once to do initial check
		this.setScrollEvent();
		
	}

	// Hide all elements
	hideAll(){
		this.hiddenElements.forEach(el => {
			let contents = el.innerText;
			let words = contents.split(' ');
			el.innerHTML = '';

			words.forEach(word => {
				let span = document.createElement('span');
				span.classList.add('typer--hidden');
				span.innerHTML = word + '\u00A0';
				el.appendChild(span);
			});
		});
	}

	// Set the scroll event, send this down to the checkInView function
	setScrollEvent(){
		let self = this;
		this.hiddenElements.forEach(el => {
			this.checkInView(el, self);
		});
	}

	checkInView(el, self){
		// If there's an offset, add that to the top of each element
		let offset = el.dataset.inViewOffset ?? 0;
		
		let elTop = el.offsetTop + parseInt(offset);
		let elBottom = elTop + el.offsetHeight;
		let windowTop = window.pageYOffset;
		let windowBottom = windowTop + window.innerHeight;

		if (elBottom >= windowTop && elTop < windowBottom) {
			// If there's a manual delay
			let delay = parseInt(el.dataset.inViewDelay) ?? 0;
			el.classList.add('typer--complete');
			setTimeout(function(){
				let totalWords = el.querySelectorAll('.typer--hidden').length;
				el.querySelectorAll('.typer--hidden').forEach((word, i) => {
					let modifier = 0;
					if(i === totalWords - 4){
						modifier = 100;
					} else if(i === totalWords - 3){
						modifier = 200;
					} else if(i === totalWords - 2){
						modifier = 300;
					} else if(i === totalWords - 1){
						modifier = 400;
					}

					setTimeout(function(modifier){
						word.classList.remove('typer--hidden');
						word.classList.add('typer--visible');
					}, (i * 100) + modifier );
				});
			}, delay);
			// Update the element list with hidden images
			this.hiddenElements = document.querySelectorAll('.typer--todo:not(.typer--complete)');
		}
	}
}