// import 'jquery'

import site from 'templates/site';
import home from 'templates/home';
import about from 'templates/about';
import article from 'templates/article';


new site();

if(document.body.classList.contains('template-home')){
	new home();
}

if(document.body.classList.contains('template-about')){
	new about();
}

if(document.body.classList.contains('template-article')){
	new article();
}