// import Helpers from "./Helpers";
import {scrollTop, generateKey, getWindowSize, splitCharacters} from "../helpers";

export default class site {

	constructor() {
		// const helpers = new Helpers();
		
		// helpers.onReady(this.setHomeTextHeight);

		this.setHomeTextHeight();
		window.addEventListener('resize', this.setHomeTextHeight);

		document.querySelector('.home_intro-image').addEventListener('click', () => this.scrollToHomeContent());
		
	}

	setHomeTextHeight(){
		let homeText = document.querySelector('.home_intro-text');
		if(homeText){
			let homeTextHeight = homeText.offsetHeight;
			homeText.style.top = window.innerHeight/2 - homeTextHeight/2 - 40 + 'px';
		}
	}

	scrollToHomeContent(){
		let homeContent = document.querySelector('.home_intro-wrap');
		if(homeContent){
			let homeContentTop = homeContent.offsetTop;
			scrollTop(document.documentElement, homeContentTop, 1000);
		}
	}
}